exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-video-da-page-jsx": () => import("./../../../src/pages/videoDAPage.jsx" /* webpackChunkName: "component---src-pages-video-da-page-jsx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-amazon-data-challenge-amazon-data-challenge-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/amazonDataChallenge/amazonDataChallenge.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-amazon-data-challenge-amazon-data-challenge-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-boosting-boosting-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/boosting/boosting.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-boosting-boosting-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-goalie-vision-goalie-vision-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/goalieVision/goalieVision.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-goalie-vision-goalie-vision-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-grid-efficient-grid-efficient-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/gridEfficient/gridEfficient.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-grid-efficient-grid-efficient-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-hackgt-hackgt-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/hackgt/hackgt.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-hackgt-hackgt-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-implicit-bias-implicit-bias-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/implicitBias/implicitBias.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-implicit-bias-implicit-bias-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-lyft-data-challenge-lyft-data-challenge-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/lyftDataChallenge/lyftDataChallenge.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-lyft-data-challenge-lyft-data-challenge-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-rodney-smith-rodney-smtih-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/rodneySmith/rodneySmtih.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-rodney-smith-rodney-smtih-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-vehicle-traj-vehicle-traj-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/vehicleTraj/vehicleTraj.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-vehicle-traj-vehicle-traj-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-vi-nl-vi-nl-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/ViNL/ViNL.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-vi-nl-vi-nl-mdx" */),
  "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-video-da-video-da-mdx": () => import("./../../../src/templates/portfolio-item.jsx?__contentFilePath=/Users/simar/Documents/PersonalSite/PersonalWebsite/src/content/VideoDA/VideoDA.mdx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx-content-file-path-users-simar-documents-personal-site-personal-website-src-content-video-da-video-da-mdx" */)
}

